import "../styles/globals.css";
import {ThemeProvider} from "next-themes";
import Layout from "../components/layout";
import {Provider} from "react-redux";
import {store} from "../redux/store";
import UserContext from "../components/UserContext";
import {useRef} from "react";
import {Analytics} from '@vercel/analytics/react';

function MyApp({Component, pageProps}) {
  const scrollRef = useRef({
    scrollPos: 0,
  });

  return (
    <>
      <Provider store={store}>
        <ThemeProvider enableSystem={true} attribute="class">
          <UserContext.Provider value={{scrollRef: scrollRef}}>
            <Layout>
              <Component {...pageProps} />
              <script async src="https://cdn.splitbee.io/sb.js"></script>
            </Layout>
          </UserContext.Provider>
        </ThemeProvider>
      </Provider>
      <Analytics/>
    </>
  );
}

export default MyApp;
